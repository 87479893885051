<template>
  <div class="text-no-wrap">
    <v-btn icon @click="$emit('edit-item', item)">
      <v-icon>
        mdi-pencil
      </v-icon>
    </v-btn>
    <v-btn icon @click="confirmDelete = true">
      <v-icon>
        mdi-delete
      </v-icon>
    </v-btn>
    <confirm-delete
      v-if="confirmDelete"
      @cancel="confirmDelete = false"
      @delete="
        $emit('delete-item', { table: sectionInfo.adminTable, _id: item._id })
      "
    ></confirm-delete>
  </div>
</template>

<script>
import ConfirmDelete from "@/components/Admin/ConfirmDelete";
export default {
  components: { ConfirmDelete },
  props: ["item", "sectionInfo"],
  data: () => ({
    confirmDelete: false
  })
};
</script>
