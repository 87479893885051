<template>
  <v-card light>
    <v-container>
      <v-form-base
        :schema="schema"
        :model="model"
        :row="{ noGutters: false }"
      />
    </v-container>
    <v-card-actions>
      <v-btn text color="error" @click="$emit('close')">
        Cancel
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn text color="success" @click="$emit('do-action')">
        {{ actionButtonText }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import VFormBase from "vuetify-form-base";

export default {
  components: {
    VFormBase
  },

  props: ["model", "schema", "actionButtonText"],

  methods: {
    //
  }
};
</script>
