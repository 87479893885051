<template>
  <div>
    <v-toolbar flat>
      <v-spacer></v-spacer>
      <v-toolbar-title class="text-uppercase">
        {{ sectionInfo.name }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="$emit('prev-page')" :disabled="pagination.page == 1">
        <v-icon>
          mdi-chevron-left
        </v-icon>
      </v-btn>
      {{ pagination.page }} of {{ pagination.pageCount }}
      <v-btn
        icon
        @click="$emit('next-page')"
        :disabled="pagination.page == pagination.pageCount"
      >
        <v-icon>
          mdi-chevron-right
        </v-icon>
      </v-btn>
    </v-toolbar>
    <v-toolbar flat>
      <v-text-field
        label="Search"
        v-model="search"
        prepend-icon="mdi-magnify"
        clearable
        @keydown.esc="search = ''"
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-btn icon x-large @click="$emit('new-item')">
        <v-icon>
          mdi-new-box
        </v-icon>
      </v-btn>
    </v-toolbar>
  </div>
</template>

<script>
export default {
  props: ["pagination", "sectionInfo", "searchField"],
  computed: {
    search: {
      get() {
        return this.searchField;
      },
      set(value) {
        this.$emit("update-search", value);
      }
    }
  }
};
</script>
