<template>
  <v-overlay>
    <v-card light>
      <v-card-title>
        Are you sure you want to delete this item?
      </v-card-title>
      <v-card-actions>
        <v-btn text color="error" @click="$emit('cancel')">Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-btn text color="success" @click="$emit('delete')">Delete</v-btn>
      </v-card-actions>
    </v-card>
  </v-overlay>
</template>

<script>
export default {};
</script>
