<template>
  <main-container>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
      hide-default-footer
    >
      <template #top="{ pagination, options }">
        <table-top
          :section-info="sectionInfo"
          :pagination="pagination"
          :searchField="search"
          @prev-page="options.page--"
          @next-page="options.page++"
          @new-item="newItem"
          @update-search="updateSearch"
        ></table-top>
      </template>
      <template #[`item.tools`]="{ item }">
        <tools
          :item="item"
          :sectionInfo="sectionInfo"
          @edit-item="editItem"
          @delete-item="adminDeleteItem"
        ></tools>
      </template>
    </v-data-table>
    <v-dialog v-model="showEditor" width="65vw">
      <editor
        :schema="schema"
        :model="model"
        :actionButtonText="actionButtonText"
        @close="showEditor = false"
        @do-action="doAction"
      ></editor>
    </v-dialog>
  </main-container>
</template>

<script>
  import MainContainer from "@/components/MainContainer";
  import TableTop from "@/components/Admin/TableTop";
  import Tools from "@/components/Admin/Tools";
  import Editor from "@/components/Admin/Editor";
  import schemas from "@/assets/schemas.json";
  import headers from "@/assets/headers.json";
  import { isArray } from "lodash";
  import { mapActions } from "vuex";
  export default {
    components: {
      MainContainer,
      TableTop,
      Tools,
      Editor,
    },
    props: ["sectionInfo"],
    data: () => ({
      search: "",
      model: {},
      showEditor: false,
      actionButtonText: "",
    }),
    computed: {
      schema() {
        return schemas[this.sectionInfo.adminTable];
      },
      headers() {
        return [
          ...headers[this.sectionInfo.adminTable],
          { text: "Tools", value: "tools" },
        ];
      },
      items() {
        let items = this.$store.state.admin[this.sectionInfo.adminTable];
        if (this.sectionInfo.adminTable == "seeHearRead") {
          items = [...items].map((item) => {
            switch (item.type) {
              case "audio":
                item.displayType = "Hear";
                break;
              case "video":
                item.displayType = "See";
                break;
              case "text":
                item.displayType = "Read";
                break;
            }

            return item;
          });
        }
        return isArray(items) ? items : [];
      },
    },
    methods: {
      ...mapActions(["adminDeleteItem", "adminUpsertItem"]),
      editItem(item) {
        this.model = { ...item };
        this.actionButtonText = "Update";
        this.showEditor = true;
      },
      updateSearch(value) {
        this.search = value;
      },
      newItem() {
        this.model = {};
        this.actionButtonText = "Add";
        this.showEditor = true;
      },
      doAction() {
        this.adminUpsertItem({
          item: this.model,
          table: this.sectionInfo.adminTable,
          actionType: this.actionButtonText.toLowerCase(),
        }).then(() => (this.showEditor = false));
      },
    },
  };
</script>
